<template>
  <b-card :title="translations['UsefulLinks-Title']">
    <div
      class="suggestion"
      @click="
        openURL(
          'https://www.youtube.com/playlist?list=PLxxFaSunZYCn2m2yGs1_zyotyheobwQia',
        )
      "
    >
      <div class="svg-icon text-dark">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="youtube"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          class="svg-inline--fa fa-youtube fa-w-15 fa-4x"
        >
          <path
            fill="currentColor"
            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
            class=""
          ></path>
        </svg>
      </div>
      <div>
        <div class="section-title mb-1">
          {{ translations["UsefulLinks-Webinars-Title"] }}
        </div>
        <div class="section-description">
          {{ translations["UsefulLinks-Webinars-SubTitle"] }}
        </div>
      </div>
    </div>
    <div
      class="suggestion"
      @click="openURL(`https://kb.rentmagic.net/${$store.state.language}`)"
    >
      <div class="svg-icon text-dark">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="book"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="svg-inline--fa fa-book fa-w-15 fa-4x"
        >
          <path
            fill="currentColor"
            d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
            class=""
          ></path>
        </svg>
      </div>
      <div>
        <div class="section-title mb-1">
          {{ translations["UsefulLinks-Manuals-Title"] }}
        </div>
        <div class="section-description">
          {{ translations["UsefulLinks-Manuals-SubTitle"] }}
        </div>
      </div>
    </div>
    <div class="suggestion" @click="openURL('mailto:software@infodatek.com')">
      <div class="svg-icon text-dark">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="envelope"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="svg-inline--fa fa-envelope fa-w-15 fa-4x"
        >
          <path
            fill="currentColor"
            d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
            class=""
          ></path>
        </svg>
      </div>
      <div>
        <div class="section-title mb-1">
          {{ translations["UsefulLinks-Email-Title"] }}
        </div>
        <div class="section-description">
          {{ translations["UsefulLinks-Email-SubTitle"] }}
        </div>
      </div>
    </div>
    <div
      class="suggestion"
      @click="openURL(`https://rentmagic.net/${$store.state.language}`)"
    >
      <div class="svg-icon text-dark">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="globe"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
          class="svg-inline--fa fa-globe fa-w-16 fa-4x"
        >
          <path
            fill="currentColor"
            d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
            class=""
          ></path>
        </svg>
      </div>
      <div>
        <div class="section-title mb-1">
          {{ translations["UsefulLinks-Website-Title"] }}
        </div>
        <div class="section-description">
          {{ translations["UsefulLinks-Website-SubTitle"] }}
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "StartupQuickLinks",
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    openURL(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
.suggestion {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  .svg-icon {
    padding-right: 20px;
    min-width: 90px;
    transition: 0.3s;
  }
}

.suggestion:hover {
  .svg-icon {
    transition: 0.3s;
    color: #f36726 !important;
  }
}
.suggestion:last-of-type {
  border-bottom: none;
}
</style>
